import { signedCoordToUnsigned } from './signed-coord-to-unsiged';

export const parsePolygon = (polygonCoords: string) => {
  const parsed = polygonCoords
    .split(':')
    .map((item) => {
      let coords = item.split('=')[1];
      let formatted: number[];
      if (coords) {
        formatted = coords.split(',').map((coord) => {
          return signedCoordToUnsigned(coord);
        });
      }
      if (formatted) {
        return {
          latitude: formatted[0],
          longitude: formatted[1],
        };
      }
      return null;
    })
    .filter((item) => item);
  return parsed && parsed.length ? [...parsed, parsed[0]] : [];
};
