import { createReducer } from 'utils/react-redux';

export const SocketReducerName = 'Socket';

export interface SocketStore {
  status: string;
}

const initialState: SocketStore = {
  status: 'disconnected',
};

export const SocketReducer = createReducer(SocketReducerName, initialState, {});
