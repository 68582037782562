module.exports = {
  'extra-color-gray': '#F0F2F5',
  'extra-color-orange': '#FF9300',
  'extra-color-blue': '#0085BA',
  'extra-color-yellow': '#FFEB00',
  'primary-color': '#0085BA',
  'primary-color-old': '#1890ff',
  'link-color': '#1890ff',
  'success-color': '#52c41a',
  'warning-color': '#faad14',
  'error-color': '#f5222d',
  extraOrangeHover: '#ff9f1a',
  labelColor: 'rgb(169, 169, 169)',
  lightGray: '#D3D3D3',
  gray: 'rgb(232, 232, 232)',
  orangeColor: 'rgb(247,183,110)',
  blackColor: 'rgb(0,0,0)',
};
