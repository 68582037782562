import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { GeneralComms } from '../../../../general-comms/general-comms';

export const archivedGeneralCommsReducerName = 'ArchivedGeneralCommsReducer';

export interface ArchivedGeneralCommsStore extends ApiTableReducer {
  srcDestFilterOptions: string[];
  txRxFilterOptions: string[];
  selectedGeneralComms?: GeneralComms;
  selectedRows?: GeneralComms[];
}

const initialState: ArchivedGeneralCommsStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    srcDest: [],
    txRx: [],
    direction: [],
  },
  sorter: {
    order: 'descend',
    field: 'txRx',
    columnKey: 'txRx',
  },
  srcDestFilterOptions: [],
  txRxFilterOptions: [],
  selectedRows: [],
};

export const ArchivedGeneralCommsReducer = createReducer(archivedGeneralCommsReducerName, initialState, {});
