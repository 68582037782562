import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { Distress } from '../../../../msi-received/distress';

export const archivedReceivedReducerName = 'ArchivedReceivedReducer';

export interface ArchivedReceivedStore extends ApiTableReducer {
  distypeFilterOptions: string[];
  rccstatusFilterOptions: string[];
  selectedDistress?: Distress;
  selectedRows?: Distress[];
  vesselNameOptions: any[];
}

const initialState: ArchivedReceivedStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    disprio: [],
    distype: [],
    mmsi: [],
    rccstatus: [],
    vesselName: [],
    disid: [],
    distime: [],
  },
  sorter: {
    order: 'descend',
    field: 'distime',
    columnKey: 'distime',
  },
  rccstatusFilterOptions: [],
  distypeFilterOptions: [],
  vesselNameOptions: [],
  selectedRows: [],
};

export const ArchivedReceivedReducer = createReducer(archivedReceivedReducerName, initialState, {});
