import * as React from 'react';
import * as Loadable from 'react-loadable';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getAuthInfo, isAdmin } from 'utils/session';

const AsyncMainComponent = Loadable({
  loader: () => import(/* webpackChunkName: "mainScreen" */ './sections/mainScreen/view/mainScreen.section'),
  loading: () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        height: '100%',
      }}
    >
      <h3 style={{ flex: 1, animation: 'blink 1.5s ease infinite' }}>Loading...</h3>
    </div>
  ),
});

const AsyncDashboardComponent = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ './sections/dashboard/view/dashboard.section'),
  loading: () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        height: '100%',
      }}
    >
      <h3 style={{ flex: 1, animation: 'blink 1.5s ease infinite' }}>Loading...</h3>
    </div>
  ),
});

const AsyncAdminDashboardComponent = Loadable({
  loader: () => import(/* webpackChunkName: "adminDashboard" */ './sections/admin/view/adminDashboard.section'),
  loading: () => (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        height: '100%',
      }}
    >
      <h3 style={{ flex: 1, animation: 'blink 1.5s ease infinite' }}>Loading...</h3>
    </div>
  ),
});

export const Routes = () => {
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() => {
          if (getAuthInfo()) {
            if (isAdmin()) {
              return <Redirect to="/admin" />;
            }
            return <Redirect to="/dashboard" />;
          }
          return <AsyncMainComponent />;
        }}
      />
      <Route
        path="/admin"
        render={({ match: url }) => {
          if (getAuthInfo() && isAdmin()) {
            return <AsyncAdminDashboardComponent parentUrl={url} />;
          }
          return <Redirect to="/" />;
        }}
      />
      <Route
        path="/dashboard"
        render={({ match: url }) => {
          if (getAuthInfo() && !isAdmin()) {  // Modified this to add a non-admin check
            return <AsyncDashboardComponent parentUrl={url} />;
          }
          return <Redirect to="/" />;
        }}
      />
    </Switch>
  );
};
