import { createReducer } from 'utils/react-redux';

export const dashboardReducerName = 'Dashboard';

export interface DashboardStore {
  wpsConfig: any;
  gmdssConfig: any;
  loading: boolean;
  providerDest: any;
  displayUserSnap: boolean;
}

const initialState: DashboardStore = {
  wpsConfig: [],
  gmdssConfig: [],
  providerDest: [],
  loading: false,
  displayUserSnap: false,
};

export const DashboardReducer = createReducer(dashboardReducerName, initialState, {});
