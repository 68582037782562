import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { DistressRelays } from '../distress-relays';

export const distressRelaysReducerName = 'DistressRelays';

export interface DistressRelaysStore extends ApiTableReducer {
  selectedRows: DistressRelays[];
  areaFilterOptions: string[];
  selectedAdvisory?: DistressRelays;
  composeState?: any;
}

const initialState: DistressRelaysStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    areaName: [],
    expiryTime: [],
    mmsi: [],
    depositTime: [],
    refId: [],
    advisoryId: [],
    advisoryStatus: [],
  },
  sorter: {
    order: 'descend',
    field: 'depositTime',
    columnKey: 'depositTime',
  },
  selectedRows: [],
  areaFilterOptions: [],
};

export const DistressRelaysReducer = createReducer(distressRelaysReducerName, initialState, {});
