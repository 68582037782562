import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { Distress } from '../distress';

export const msiReceivedReducerName = 'MsiReceived';

export interface MsiReceivedStore extends ApiTableReducer {
  selectedRows: Distress[];
  distypeFilterOptions: string[];
  rccstatusFilterOptions: string[];
  vesselNameOptions: string[];
  selectedDistress?: Distress;
  msgDestination?: string;
  msgBody?: string;
  msgComposeVisible: boolean;
  msgPrio?: string;
  msgSuccess: boolean;
}

const initialState: MsiReceivedStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    disprio: [],
    distypeText: [],
    rccstatus: [],
    vesselName: [],
    direction: [],
    mmsi: [],
    disid: [],
    distime: [],
  },
  sorter: {
    order: 'descend',
    field: 'distime',
    columnKey: 'distime',
  },
  selectedRows: [],
  rccstatusFilterOptions: [],
  distypeFilterOptions: [],
  vesselNameOptions: [],
  msgComposeVisible: false,
  msgPrio: '3',
  msgSuccess: false,
};

export const MsiReceivedReducer = createReducer(msiReceivedReducerName, initialState, {});
