import { createReducer } from 'utils/react-redux';
import { WorldMap } from '../world-map';

export const worldMapReducerName = 'World Map';

export interface WorldMapStore {
  loading: boolean;
  data: WorldMap[];
  selectedShip?: WorldMap;
  mmsiShip?: string[];
  searchedMMSI?: WorldMap;
  searchMMSI: boolean;
  mmsiSearchValue?: string;
}

const initialState: WorldMapStore = {
  loading: false,
  data: [],
  searchMMSI: false,
};

export const WorldMapReducer = createReducer(worldMapReducerName, initialState, {});
