import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { MMSIAdvisory } from 'src/shared/types/mmsi-advisory';

export const msiStatusDetailsReducerName = 'MsiStatusDetails';

export interface MsiStatusDetailsStore extends ApiTableReducer {
  selectedRows: MMSIAdvisory[];
  mmsiFilterOptions: string[];
  advisoryDelvStatusFilterOptions: string[];
  smsTapStatusFilterOptions: string[];
  advisory:Object
}

const initialState: MsiStatusDetailsStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {},
  sorter: {},
  selectedRows: [],
  mmsiFilterOptions: [],
  smsTapStatusFilterOptions: [],
  advisoryDelvStatusFilterOptions: [],
  advisory:{},
};

export const MsiStatusDetailsReducer = createReducer(msiStatusDetailsReducerName, initialState, {});
