import { createReducer } from 'utils/react-redux';
import { NavArea } from 'src/sections/dashboard/routes/world-map/world-map';
import { Ship } from '../map';//, MapCluster } from '../map';

export const mapReducerName = 'Map';

export interface MapStore {
  navareas: NavArea[];
  ships: Ship[];
  distressArea?: NavArea;
  //selectedCluster?: MapCluster;
  zoom: number;
  loading: boolean;
  searchedLocations: any[];
  clustering: boolean;
  ready: boolean;
  shouldDisplay: boolean;
  focusNavArea: boolean;
  layers: any;
  searchedShip: Ship;
  savedPosition?: any;
  gridlines?: boolean;
  visibleNavareas?: boolean;
}

const initialState: MapStore = {
  navareas: [],
  ships: [],
  distressArea: null,
  zoom: 2,
  loading: false,
  searchedLocations: [],
  clustering: false,
  ready: false,
  shouldDisplay: true,
  focusNavArea: false,
  layers: [],
  searchedShip: null,
  //gridlines: true,
  //visibleNavareas: true,
};

export const MapReducer = createReducer(mapReducerName, initialState, {});
