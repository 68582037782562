import { createHashHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import logger from './logger';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';

export const history = createHashHistory({
  hashType: 'slash',
});

export const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
export let store: any;

export const getStore = () => {
  return store;
};

export default function configureStore(preloadedState) {
  store = createStore(
    createRootReducer(),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware, logger)),
  );

  store.asyncReducers = store.asyncReducers || {};

  if (module['hot']) {
    // Enable Webpack hot module replacement for reducers
    module['hot'].accept('./reducers', () => {
      store.replaceReducer(createRootReducer());
    });
  }

  return store;
}
