import { createReducer } from 'utils/react-redux';

export const msiComposeReducerName = 'MsiCompose';

export interface MsiComposeStore {
  visible: boolean;
  messageType?: string;
  priority?: string;
  target?: string;
  nations?: string[];
  selectedNation?: string;
  advId?: string;
  expiryMeasure?: string;
  schedule?: string;
  expiryPeriod?: number;
  body?: string;
  geography?: string;
  navArea?: string;
  metArea?: string;
  loading: boolean;
  swCorner?: string;
  swCornerCompleted?: boolean;
  extendWidth?: number;
  extendHeight?: number;
  center?: string;
  centerCompleted?: boolean;
  radius?: number;
  parentNavArea?: string;
  subject?: string;
  coastalArea?: string;
  scheduleDate?: any;
  recipients: any[];
  navareas: any[];
  metareas: any[];
  coastalareas: any[];
  subjects: any[];
  refId?: string;
  error?: Error;
  sending: boolean;
  success: boolean;
  resultText?: string;
  fieldValidationError?: string[];
  categories?: string[];
  targets?: string[];
  geoRules?: any;
  prios?: string[];
  exception?: boolean;
  delayPeriod?: number;
  delayMeasure?: string;
  startDate?: any;
  endDate?: any;
  interval?: any;
  repeat?: number;
  maxMsiExpiry?: number;
  refEpoch?: string;
  intervalHours?: number;
  intervalMinutes?: number;
}

export const initialState: MsiComposeStore = {
  visible: false,
  sending: false,
  loading: false,
  success: false,
  navareas: [],
  metareas: [],
  coastalareas: [],
  subjects: [],
  recipients: [],
  swCorner: '',
  fieldValidationError: [],
  selectedNation: null,
  nations: [],
  categories: [],
  targets: [],
  geoRules: {},
  prios: [],
  exception: false,
  centerCompleted: false,
  swCornerCompleted: false,
};

export const MsiComposeReducer = createReducer(msiComposeReducerName, initialState, {});
