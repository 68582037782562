import { createReducer } from 'utils/react-redux';

export const versionReducerName = 'Version';

export interface VersionStore {
  api: {
    version: string;
    env: string;
    websiteVersion;
  };
  app: {
    version: string;
    env: string;
  };
  freqCheck: number;
}

const initialState: VersionStore = {
  app: {
    version: '0.0.0',
    env: 'dev',
  },
  api: {
    version: '0.0.0',
    env: 'dev',
    websiteVersion: '0.0.0',
  },
  freqCheck: 1,
};

export const VersionReducer = createReducer(versionReducerName, initialState, {});
