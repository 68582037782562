import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { Archived, FileContent } from '../archived';

export const archivedReducerName = 'Archived';

export interface ArchivedStore extends ApiTableReducer {
  areaFilterOptions: string[];
  advTypeFilterOptions: string[];
  selectedAdvisory?: Archived;
  fileContent: FileContent;
  selectedRows: Archived[];
}

const initialState: ArchivedStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    prio: [],
    refId: [],
    advisoryId: [],
    advType: [],
    areaType: [],
    depositTime: [],
    expiryTime: [],
    transmission: [],
    advisoryStatus: [],
  },
  sorter: {
    order: 'descend',
    field: 'depositTime',
    columnKey: 'depositTime',
  },
  selectedRows: [],
  areaFilterOptions: [],
  advTypeFilterOptions: [],
  fileContent: {},
};

export const ArchivedReducer = createReducer(archivedReducerName, initialState, {});
