import { createReducer } from 'utils/react-redux';
import { DistressAlert } from 'src/sections/dashboard/dashboard';

export const distressAlertReceivedReducerName = 'DistressAlertReceived';

export interface DistressAlertReceivedStore {
  alerts: DistressAlert[];
  safetyRequireAttention: boolean;
  unreadMsiCount?: number;
}

const initialState: DistressAlertReceivedStore = {
  alerts: [],
  safetyRequireAttention: false,
};

export const DistressAlertReceivedReducer = createReducer(distressAlertReceivedReducerName, initialState, {});
