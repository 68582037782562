import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { ShipInfo } from '../ship-info';

export const shipInfoReducerName = 'ShipInfo';

export interface ShipInfoStore extends ApiTableReducer {
  srcDestFilterOptions: string[];
  txRxFilterOptions: string[];
  selectedGeneralComms?: ShipInfo;
  smsComposeVisible: boolean;
  smsDestination?: string;
  smsMessage?: string;
  smsSuccess?: boolean;
  smsSuccessText?: string;
  selectedRows: ShipInfo[];
  searchField: string;
  searchValue: string;
  searchJoin: string;
  secondarySearchField?: string;
  secondarySearchValue?: string;
}

const initialState: ShipInfoStore = {
  loading: false,
  smsComposeVisible: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    mmsi: [],
  },
  sorter: {},
  selectedRows: [],
  srcDestFilterOptions: [],
  txRxFilterOptions: [],
  searchField: 'any',
  searchValue: '',
  searchJoin: 'none',
};

export const ShipInfoReducer = createReducer(shipInfoReducerName, initialState, {});
