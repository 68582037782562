import { createReducer } from 'utils/react-redux/create-reducer';

export const mainScreenReducerName = 'Login';

export interface LoginStore {
  user: object;
  loading: boolean;
  error?: Error;
  loginView: string;
  providers: any[];
  loadingProviders: boolean;
  selectedProvider?: any;
  tokenExpiration?: any;
  shouldResetPassword: boolean;
}

const initialState: LoginStore = {
  user: {},
  loading: false,
  loginView: 'main',
  providers: [],
  loadingProviders: false,
  shouldResetPassword: false,
};

export const MainScreenReducer = createReducer(mainScreenReducerName, initialState, {});
