import { createReducer } from 'utils/react-redux';
import { Ship } from '../../map/map';

export const VesselDetailsReducerName = 'VesselDetails';

export interface VesselDetailsStore {
  currentVessel?: Ship;
  loading: boolean;
  error: any;
}

const initialState: VesselDetailsStore = {
  loading: false,
  error: null,
};

export const VesselDetailsReducer = createReducer(VesselDetailsReducerName, initialState, {});
