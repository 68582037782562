import { parsePolygon } from './maps/parse-polygon-coords';

export const getAuthInfo = () => {
  const authInfo = window.sessionStorage.getItem('authInfo')
    ? JSON.parse(window.sessionStorage.getItem('authInfo'))
    : null;
  if (authInfo) {
    if (authInfo.userType.toLowerCase() === 'iridium' && !authInfo.loginCompleted) {
      return null;
    }
    return authInfo;
  }
  return null;
};

export const getUserIaccess = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.iAccess : null;
};

export const getUserEmailFtpRole = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.emailFtpRole : null;
}

export const getUserFTPAccess = () => {
  const iAccess = getUserIaccess();
  return iAccess.includes('FTP');
};

export const getUserId = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.id : null;
};

export const getUserSSH = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.hasSSH : false;
};

export const getUserProviderId = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.providerId : null;
};
export const getUserAdvCat = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.advCat : null;
};

export const getProvider = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.provider : {};
};

export const getProviderAreas = () => {
  const providerInfo = getProvider();
  return providerInfo
    ? providerInfo.areas.map((area) => {
        return {
          ...area,
          polygon: parsePolygon(area.polygoneCoords),
        };
      })
    : [];
};

export const getProviderType = () => {
  const providerInfo = getProvider();
  return providerInfo ? providerInfo.providerType : null;
};

export const getCSVExport = () => {
  const providerInfo = getProvider();
  return providerInfo ? providerInfo.CSVExport === 'YES' : false;
};

export const getUserType = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.userType : null;
};

export const getMapAccess = () => {
  const userInfo = getAuthInfo();
  return userInfo && userInfo.mapAccess && userInfo.mapAccess !== 'NO';
};
export const getMapAccessVal = () => {
  const userInfo = getAuthInfo();
  return userInfo && userInfo.mapAccess;
};

export const getMSIMapAccess = () => {
  const userInfo = getAuthInfo();
  return userInfo && userInfo.mapAccess && userInfo.mapAccess.includes('MSIP');
};

export const getRCCMapAccess = () => {
  const userInfo = getAuthInfo();
  return userInfo && userInfo.mapAccess && userInfo.mapAccess.includes('RCC');
};

export const getUserMapAccess = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.mapAccess: "N/A";
}

export const getGcomsAccess = () => {
  const userInfo = getAuthInfo();
  return userInfo && /\bGCOMS\b/.test(userInfo.iAccess);
};

export const getUserPriorities = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.priorities : '';
};

export const getCategories = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.advisoryCategory : '';
};

export const passwordReset = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.passReset === 'YES' : false;
};

export const removeSession = () => {
  window.sessionStorage.clear();
};

export const isAdmin = () => {
  const userInfo = getAuthInfo();
  return userInfo && (userInfo.userType === 'MSIPADMIN' || userInfo.userType === 'ADMIN');
};

export const adminType = () => {
  const userInfo = getAuthInfo();
  return ( userInfo && isAdmin() )? userInfo.userType : null;
}

export const logout = () => {
  window.sessionStorage.removeItem('authInfo');
};

export const getUserName = () => {
  const userInfo = getAuthInfo();
  return userInfo ? userInfo.name : null;
}