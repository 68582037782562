export const signedCoordToUnsigned = (value: string) => {
  let trimmedValue = value.trim();
  let result: number;
  let letter = trimmedValue[trimmedValue.length - 1];

  const stringVal = trimmedValue.split('-');
  const degrees = parseInt(stringVal[0]);
  const decimalMinute = stringVal[1] ? parseInt(stringVal[1].substring(0, stringVal[1].length - 1)) / 60 : 0;
  const newValue = degrees + decimalMinute;

  result = Math.abs(newValue);

  switch (letter) {
    case 'N':
    case 'E':
      break;
    case 'S':
    case 'W':
      result = result * -1;
      break;
    default:
      return 0;
  }
  return Number(result.toFixed(6));
};
