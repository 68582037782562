import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './configure-store';
import { MapReducer, mapReducerName } from './shared/components/map/state/map.reducer';
import { ShipInfoReducer, shipInfoReducerName } from './sections/dashboard/routes/ship-info/state/ship-info.reducer';
import { VersionReducer, versionReducerName } from './shared/components/version/state/version.reducer';

import {
  DistressRelaysReducer,
  distressRelaysReducerName,
} from './sections/dashboard/routes/distress-relays/state/distress-relays.reducer';
import {
  MsiComposeReducer,
  msiComposeReducerName,
} from './sections/dashboard/routes/msi-sent/view/components/msi-compose/state/msi-compose.reducer';
import {
  GeneralCommsReducer,
  generalCommsReducerName,
} from './sections/dashboard/routes/general-comms/state/general-comms.reducer';
import {
  DistressAlertReceivedReducer,
  distressAlertReceivedReducerName,
} from './sections/dashboard/view/components/distress-alert-received/state/distress-alert-received.reducer';
import {
  MsiReceivedReducer,
  msiReceivedReducerName,
} from './sections/dashboard/routes/msi-received/state/msi-received.reducer';
import { ArchivedReducer, archivedReducerName } from './sections/dashboard/routes/archived/state/archived.reducer';
import {
  ArchivedReceivedReducer,
  archivedReceivedReducerName,
} from './sections/dashboard/routes/archived/routes/received/state/received.reducer';
import {
  ArchivedGeneralCommsReducer,
  archivedGeneralCommsReducerName,
} from './sections/dashboard/routes/archived/routes/general-comms/state/general-comms.reducer';
import {
  DistressReceivedReducer,
  distressReceivedReducerName,
} from './sections/dashboard/routes/distress-received/state/distress-received.reducer';
import { MsiSentReducer, msiSentReducerName } from './sections/dashboard/routes/msi-sent/state/msi-sent.reducer';
import { MainScreenReducer, mainScreenReducerName } from './sections/mainScreen/state/mainScreen.reducer';
import { worldMapReducerName, WorldMapReducer } from './sections/dashboard/routes/world-map/state/world-map.reducer';
import {
  distressRelayDetailsReducerName,
  DistressRelayDetailsReducer,
} from './shared/components/distress-alert-details/state/distress-alert-details.reducer';
import {
  msiStatusDetailsReducerName,
  MsiStatusDetailsReducer,
} from './shared/components/msi-status-details/state/msi-status-details.reducer';

import { PasswordReducer, reducerName } from './shared/components/popover-user-data/state/password-reset.reducer';

import { dashboardReducerName, DashboardReducer } from './sections/dashboard/state/dashboard.reducer';

import {
  VesselDetailsReducer,
  VesselDetailsReducerName,
} from './shared/components/ship-info-modal/state/vessel-details.reducer';
import { SocketReducerName, SocketReducer } from './shared/components/socket-handler/state/socket.reducer';

export default (asyncReducers?: Reducer) => {
  return combineReducers({
    router: connectRouter(history),
    [dashboardReducerName]: DashboardReducer,
    [mapReducerName]: MapReducer,
    [shipInfoReducerName]: ShipInfoReducer,
    [versionReducerName]: VersionReducer,
    [distressRelaysReducerName]: DistressRelaysReducer,
    [msiComposeReducerName]: MsiComposeReducer,
    [generalCommsReducerName]: GeneralCommsReducer,
    [msiReceivedReducerName]: MsiReceivedReducer,
    [distressAlertReceivedReducerName]: DistressAlertReceivedReducer,
    [archivedReducerName]: ArchivedReducer,
    [archivedReceivedReducerName]: ArchivedReceivedReducer,
    [archivedGeneralCommsReducerName]: ArchivedGeneralCommsReducer,
    [distressReceivedReducerName]: DistressReceivedReducer,
    [mainScreenReducerName]: MainScreenReducer,
    [msiSentReducerName]: MsiSentReducer,
    [worldMapReducerName]: WorldMapReducer,
    [distressRelayDetailsReducerName]: DistressRelayDetailsReducer,
    [msiStatusDetailsReducerName]: MsiStatusDetailsReducer,
    [reducerName]: PasswordReducer,
    [VesselDetailsReducerName]: VesselDetailsReducer,
    [SocketReducerName]: SocketReducer,
    ...asyncReducers,
  });
};
