import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';

export const distressReceivedReducerName = 'DistressReceived';

export interface DistressReceivedStore extends ApiTableReducer {
  showMap: boolean;
  dismissReason?: string;
  showReasonModal: boolean;
  success: boolean;
  successMessage: string;
  searchedShip?: any;
  searchedError?: boolean;
  vesselNameTyped?: string;
}

const initialState: DistressReceivedStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    rccstatus: [],
    mmsi: [],
    msgtypeText: [],
    vesselName: [],
    disid: [],
    distime: [],
    distype: [],
  },
  sorter: {
    order: 'descend',
    field: 'distime',
    columnKey: 'distime',
  },
  showMap: false,
  showReasonModal: false,
  success: false,
  successMessage: '',
};

export const DistressReceivedReducer = createReducer(distressReceivedReducerName, initialState, {});
