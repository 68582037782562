import { createReducer } from 'utils/react-redux';

export const reducerName = 'PasswordReset';

export interface PasswordResetStore {
  success: boolean;
  completed: boolean;
  loading: boolean;
}

const initialState: PasswordResetStore = {
  success: false,
  completed: false,
  loading: false,
};

export const PasswordReducer = createReducer(reducerName, initialState, {});
