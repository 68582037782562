import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { Advisory, FileContent } from '../advisory';

export const msiSentReducerName = 'MsiSent';

export interface MsiSentStore extends ApiTableReducer {
  selectedRows: Advisory[];
  areaFilterOptions: string[];
  advTypeFilterOptions: string[];
  selectedAdvisory?: Advisory;
  fileContent: FileContent;
  currentPopup: string;
}

const initialState: MsiSentStore = {
  loading: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    prio: [],
    advType: [],
    areaType: [],
    expiryTime: [],
    refId: [],
    advisoryId: [],
  },
  sorter: {
    order: 'descend',
    field: 'depositTime',
    columnKey: 'depositTime',
  },
  selectedRows: [],
  areaFilterOptions: [],
  advTypeFilterOptions: [],
  fileContent: {},
  currentPopup: '',
};

export const MsiSentReducer = createReducer(msiSentReducerName, initialState, {});
