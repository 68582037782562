import { Reducer, ReducersMapObject } from 'redux';

export interface HandledReducer extends Reducer {
  handlers: ReducersMapObject;
  sliceName: string;
  initialState: any;
  addHandlers: (newHandlers: ReducersMapObject) => void;
}

export function createReducer(sliceName: string, initialState: any, handlers: any): HandledReducer {
  function reducer() {
    var state = arguments && arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments[1];

    if (action.type in reducer.handlers) {
      return reducer.handlers[action.type](state, action);
    }
    return state;
  }
  reducer.sliceName = sliceName;
  reducer.handlers = handlers || {};
  reducer.initialState = initialState;

  reducer.addHandlers = function(newHandlers: ReducersMapObject) {
    reducer.handlers = { ...reducer.handlers, ...newHandlers };
  };
  return reducer;
}
