import * as React from 'react';
import configureStore, { history } from './configure-store';
const store = configureStore({});
import { render } from 'react-dom';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Routes } from './routes';
const theme = require('./theme.js');
// * Styles
import './index.css';
import 'antd/dist/antd.less';

render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes></Routes>
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('react-root'),
);
