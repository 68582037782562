import { createReducer } from 'utils/react-redux';
import { ApiTableReducer } from 'src/shared/components/api-table/api-table';
import { GeneralComms } from '../general-comms';

export const generalCommsReducerName = 'GeneralComms';

export interface GeneralCommsStore extends ApiTableReducer {
  srcDestFilterOptions: string[];
  txRxFilterOptions: string[];
  selectedGeneralComms?: GeneralComms;
  smsComposeVisible: boolean;
  smsDestination?: string;
  smsMessage?: string;
  smsSuccess?: boolean;
  smsSuccessText?: string;
  selectedRows: GeneralComms[];
}

const initialState: GeneralCommsStore = {
  loading: false,
  smsComposeVisible: false,
  data: [],
  currentPage: 1,
  pageSize: 10,
  searchTerm: '',
  total: 0,
  filters: {
    srcDest: [],
    txRx: [],
    direction: [],
  },
  sorter: {
    order: 'descend',
    field: 'txRx',
    columnKey: 'txRx',
  },
  selectedRows: [],
  srcDestFilterOptions: [],
  txRxFilterOptions: [],
};

export const GeneralCommsReducer = createReducer(generalCommsReducerName, initialState, {});
